@use "./functions" as *;

* {
	scrollbar-color: v("black") rgba(0, 0, 0, 0.1); /* scroll thumb & track */
	scrollbar-width: thin; /* "auto" or "thin"  */
}

/* Works on Chrome/Edge/Safari */

*::-webkit-scrollbar {
	inline-size: 0.5rem;
}

*::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb {
	background-color: v("black");
}

body {
	--accent: var(--home);
	--accent-shade: var(--home-shade);
	background-color: v("accent");
	overflow-x: hidden;
	color: v("black");
	font: normal 1rem / 1.125 Effra, sans-serif;

	::selection {
		background-color: v("accent-shade");
		color: v("white");
	}
}

img,
picture,
svg {
	pointer-events: none !important;
	user-select: none !important;
}
