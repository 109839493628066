@use "./functions" as *;
@use "./settings" as *;

%container {
	--padding-inline: #{fluid-rem(1rem, 2.5rem, 768 1480)};
	--vw-unit: 50%;
	padding-inline: max(
		var(--padding-inline),
		var(--vw-unit) - var(--container) / 2
	);

	&-bleed-left {
		--padding-inline: #{fluid-rem(1rem, 2.5rem, 768 1480)};
		--vw-unit: 50%;
		padding-inline-end: max(
			var(--padding-inline),
			(var(--vw-unit) - var(--container) / 2) * 2
		);
	}

	&-bleed-right {
		--padding-inline: #{fluid-rem(1rem, 2.5rem, 768 1480)};
		--vw-unit: 50%;
		padding-inline-start: max(
			var(--padding-inline),
			(var(--vw-unit) - var(--container) / 2) * 2
		);
	}
}

%sr-only {
	clip: rect(0, 0, 0, 0);
	position: absolute;
	margin: -1px;
	border-width: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	white-space: nowrap;
}
