@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.industry-insights {
	@extend %container;
	position: relative;
	margin-block-start: fluid-rem(2rem, 12rem);
	padding-block: fluid-rem(4rem, 10rem) fluid-rem(4rem, 6rem);

	&__title {
		@extend %sr-only;
	}

	.marquee {
		position: absolute;
		top: fluid-rem(3rem, 6rem);
		left: 0;
		z-index: -1;
		width: 100%;
		height: auto;
	}

	.blog,
	.cta {
		padding-block: fluid-rem(3rem, 6rem);

		@media (width < 1700px) {
			padding-right: 5vw;
		}
	}

	.blog {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		padding-inline: fluid-rem(0rem, 6rem, 1700 3840);

		@media (width < 1480px) {
			grid-template-columns: 1fr;
			place-items: center;
			gap: fluid-rem(3rem, 6rem);
		}

		&__image {
			aspect-ratio: v(ratio);
			width: min(var(--max-width, 35rem), 90%);
			height: auto;

			// @media (width < 1480px) {
			// 	width: min(55rem, 70%);
			// }
		}

		&__articles {
			width: min(60rem, 100%);
		}

		.article-card {
			&:not(:first-of-type) {
				margin-block-start: 2rem;
			}
		}

		// .article {
		// 	display: grid;
		// 	position: relative;
		// 	grid-template-columns: auto 1fr;
		// 	align-items: center;
		// 	transition: background-color 0.5s linear;
		// 	cursor: pointer;
		// 	background-color: transparent;
		// 	isolation: isolate;
		// 	overflow: hidden;

		// 	@media (width < 580px) {
		// 		grid-template-columns: 1fr;
		// 	}

		// 	&:not(:first-of-type) {
		// 		margin-block-start: 2rem;
		// 	}

		// 	&:focus-within,
		// 	&:hover {
		// 		background-color: v("accent-shade");
		// 	}

		// 	&__picture {
		// 		aspect-ratio: 1;

		// 		@media (width >= 580px) {
		// 			width: fluid-rem(6rem, 12rem);
		// 			height: auto;
		// 		}

		// 		img {
		// 			@include size(100%);
		// 			object-fit: cover;
		// 			object-position: center;
		// 		}
		// 	}

		// 	&__header {
		// 		padding: 1rem fluid-rem(1rem, 2rem);
		// 	}

		// 	&__category {
		// 		@extend %pl;
		// 		margin-block-end: 1rem;
		// 		font-weight: bold;
		// 		line-height: 1.125;
		// 		text-transform: uppercase;
		// 	}

		// 	&__title {
		// 		@extend %h5;
		// 		color: v("white");
		// 		line-height: 1.125;
		// 	}

		// 	&__link {
		// 		&::before {
		// 			position: absolute;
		// 			z-index: -1;
		// 			inset: 0;
		// 			content: "";
		// 		}

		// 		&:focus {
		// 			outline: 1px solid transparent;
		// 		}
		// 	}

		// 	&__excerpt {
		// 		@extend %pl;
		// 		margin-block-start: 1rem;
		// 		line-height: 1.125;
		// 	}
		// }
	}

	.cta {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: start;
		gap: 0;
		padding-inline: fluid-rem(1rem, 6rem, 1700 3840);

		@media (width < 1024px) {
			grid-template-columns: 1fr 2fr;
			// gap: fluid-rem(4rem, 8rem);
			gap: 8rem;
		}

		@media (width < 768px) {
			grid-template-rows: max-content auto;
			grid-template-columns: 1fr;
			place-content: start;
		}

		&__image {
			width: min(var(--max-width, 48rem), 100%);
			height: auto;

			@media (width < 768px) {
				transform: translateX(-25%) rotate(90deg) scaleY(-1);
				transform-origin: center;
			}
		}

		&__header {
			justify-self: start;
		}

		&__title {
			@extend %h4;
			margin-block-end: 0.5rem;
		}

		&__link {
			color: v("white");
		}
	}
}
