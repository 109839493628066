@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.pagination {
	display: flex;
	align-items: center;

	&__pages {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-inline: 2rem;
	}

	.page-numbers,
	&__link {
		@extend %pxl;
		@include size(1.5rem);
		display: flex;
		justify-content: center;
		align-items: center;
		transition: color 0.2s ease-in-out;
		font-weight: 800;
		text-transform: lowercase;

		&:hover {
			color: v("_highlight", var(--white));
		}

		&--prev {
			margin-inline-end: 1rem;
		}

		&--next {
			margin-inline-start: 1rem;
		}

		&--prev,
		&--next {
			@include size(2rem);

			svg {
				@include size(1.2em);
			}
		}

		&[aria-disabled] {
			opacity: 0.25;
			pointer-events: none;
		}

		&[aria-current] {
			border-color: currentColor;
			color: v("_highlight", var(--white));
		}
	}
}
