@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.filters {
	&__title {
		@extend %pl;
		border-block-end: 0.5rem solid;
		padding-block-end: 1.5rem;
		color: v(_highlight);
		font-weight: 800;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: fluid-rem(0.75rem, 1rem);

		@media (width < 768px) {
			padding-block-start: 1rem;
		}

		// @media (width < 520px) {
		// 	align-items: center;

		// 	> * {
		// 		flex: 1 1 calc(50% - 1rem);
		// 		text-align: center;
		// 		// flex-basis: 50%;
		// 	}
		// }
	}

	&__link {
		@extend %pxl;
		transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
		border-block-end: 0.5rem solid transparent;
		padding-block: fluid-rem(0.25rem, 1.5rem) fluid-rem(0rem, 1.5rem);
		font-weight: 800;
		text-transform: lowercase;

		&:hover {
			color: v("_highlight");
		}

		&[aria-current] {
			border-color: transparent;
			color: v("_highlight");
		}
	}
}
