@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.article-card {
	display: grid;
	position: relative;
	grid-template-columns: auto 1fr;
	align-items: center;
	transition: background-color 0.4s linear;
	cursor: pointer;
	background-color: transparent;
	isolation: isolate;
	overflow: hidden;

	@media (width < 580px) {
		grid-template-columns: 1fr;
	}

	> * {
		&:empty {
			display: none;
		}
	}

	&:focus-within,
	&:hover {
		background-color: v("accent-shade");
	}

	&__picture {
		aspect-ratio: 1;

		@media (width >= 580px) {
			width: 200px;
			height: auto;
		}

		img {
			@include size(100%);
			object-fit: cover;
			object-position: center;
		}
	}

	&__header {
		padding: 1rem fluid-rem(1rem, 2rem);
	}

	&__category {
		@extend %pl;
		margin-block-end: 1rem;
		font-weight: bold;
		line-height: 1.125;
		text-transform: uppercase;
	}

	&__title {
		@extend %h5;
		transition: color 0.4s linear;
		max-block-size: 5.625rem;
		overflow: hidden;
		color: v(_highlight, "white");
		line-height: 1.25;
		text-overflow: ellipsis;

		@supports (-webkit-line-clamp: 1) {
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			max-block-size: unset;
		}
	}

	&__link {
		&::before {
			position: absolute;
			z-index: 2;
			inset: 0;
			content: "";
		}

		&:focus {
			outline: 1px solid transparent;
		}
	}

	&__date,
	&__excerpt {
		@extend %pl;
		margin-block-start: 1rem;
		line-height: 1.125;
	}

	&__excerpt {
		max-block-size: 2.75rem;
		overflow: hidden;
		text-overflow: ellipsis;

		@supports (-webkit-line-clamp: 1) {
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			max-block-size: unset;
		}
	}

	&__date {
		display: inline-block;
	}

	&__cta {
		margin-block-start: 1rem;
		color: v(_highlight, "white");
	}

	&--featured {
		// cursor: pointer;

		&:hover {
			background-color: transparent;

			.article-card__cta {
				transform: translateX(0);
				background: linear-gradient(var(--black), var(--black))
					no-repeat 0 100%/100% 100%;
				color: var(--white);
			}
		}

		.article-card {
			&__picture {
				@media (width >= 580px) {
					width: fluid-rem(10rem, 18rem);
					height: auto;
				}
			}

			&__header {
				padding-inline-end: 0;
			}

			&__title {
				width: 100%;
				max-block-size: 9rem;
				overflow: hidden;
				color: v(_highlight, "white");
				font: 700 fluid-rem(1.5rem, 2.5rem) / 1.15 v("font");
				line-height: 1.2em;
				text-overflow: ellipsis;

				@supports (-webkit-line-clamp: 1) {
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					max-block-size: unset;
				}
			}
		}
	}
}
