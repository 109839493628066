@import "../../node_modules/swiper/swiper-bundle.min.css";
:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --off-white: hsl(0, 0%, 94%);
  --off-black: hsl(345, 6%, 13%);
  --transparent-w: hsla(0, 0%, 100%, 0);
  --transparent-b: hsla(0, 0%, 0%, 0);
  --highlight: hsl(40, 81%, 66%);
  --search: hsl(40, 78%, 61%);
  --search-shade: hsl(40, 78%, 46%);
  --home: hsl(210, 50%, 59%);
  --home-shade: hsl(211, 34%, 46%);
  --services: hsl(313, 34%, 59%);
  --services-shade: hsl(305, 24%, 49%);
  --we-help: hsl(254, 32%, 62%);
  --we-help-shade: hsl(254, 20%, 53%);
  --we-are: hsl(2, 47%, 60%);
  --we-are-shade: hsl(2, 34%, 52%);
  --work-together: hsl(22, 65%, 56%);
  --work-together-shade: hsl(22, 69%, 45%);
  --startups: hsl(177, 26%, 50%);
  --startups-shade: hsl(178, 60%, 32%);
  --clients: hsl(162, 100%, 31%);
  --clients-shade: hsl(162, 95%, 25%);
  --insights: hsl(194, 53%, 49%);
  --insights-shade: hsl(194, 100%, 32%);
  --get-in-touch: hsl(280, 43%, 61%);
  --get-in-touch-shade: hsl(281, 30%, 43%);
  --easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
  --easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
  --easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  --easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
  --easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
  --easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
  --easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
  --easeInOutExpo: cubic-bezier(1, 0, 0, 1);
  --easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
  --easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  --easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  --easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  --font: effra, sans-serif;
  --container: 1480px;
  --z-header: 1000;
  --z-toggler: 1100;
  --z-menu: 1050;
  --z-search: 2000;
  --z-search-toggler: 2050;
  --z-controls: 900;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Effra_W_Lt.woff2") format("woff2");
  font-family: effra;
  font-display: swap;
}
@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Effra_W_Rg.woff2") format("woff2");
  font-family: effra;
  font-display: swap;
}
@font-face {
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Effra_W_Md.woff2") format("woff2");
  font-family: effra;
  font-display: swap;
}
@font-face {
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Effra_W_Bd.woff2") format("woff2");
  font-family: effra;
  font-display: swap;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-text-size-adjust: auto;
  -ms-text-size-adjust: auto;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: auto;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

/*p,
h1, h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
	// hyphens: auto;
}*/
a {
  word-wrap: break-word;
  color: inherit;
  overflow-wrap: break-word;
}

* {
  scrollbar-color: var(--black) rgba(0, 0, 0, 0.1); /* scroll thumb & track */
  scrollbar-width: thin; /* "auto" or "thin"  */
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  inline-size: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--black);
}

body {
  --accent: var(--home);
  --accent-shade: var(--home-shade);
  background-color: var(--accent);
  overflow-x: hidden;
  color: var(--black);
  font: normal 1rem/1.125 Effra, sans-serif;
}
body ::selection {
  background-color: var(--accent-shade);
  color: var(--white);
}

img,
picture,
svg {
  pointer-events: none !important;
  user-select: none !important;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
.main .page__title {
  font: 500 clamp( 		3.25rem, 		2.2319004525rem + 4.3438914027vw, 		6.25rem 	)/1 var(--font);
  text-transform: uppercase;
}

button.link[data-size=big],
a.link[data-size=big], .main .page__content h2, .section__title, .section__raw h2 {
  font: 700 clamp( 		2rem, 		1.1515837104rem + 3.6199095023vw, 		4.5rem 	)/1.125 var(--font);
  text-transform: uppercase;
}

.accordion__title, .form--search .field__label, .menu__wrapper .menu__list > .menu-item > .menu-item-link, .main .page__content h3, .main .page__content h2, .section__raw h3 {
  font: 700 clamp( 		1.5rem, 		0.9909502262rem + 2.1719457014vw, 		3rem 	)/1.25 var(--font);
  text-transform: uppercase;
}

button.link,
a.link, .industry-insights .cta__title, .footer .contact__title, .footer .newsletter__title, .main .page__content h4, .main .page__content h3, .section__raw h4 {
  font: 400 clamp( 		1.25rem, 		0.9106334842rem + 1.4479638009vw, 		2.25rem 	)/1.333 var(--font);
}

.search-result__link, .article-card__title, .colored-box__title, .search-menu .search-menu__link, .main .page__content h5, .main .page__content h4, .section__raw h5, .section__subtitle {
  font: 700 clamp( 		1.125rem, 		0.9977375566rem + 0.5429864253vw, 		1.5rem 	)/1.333 var(--font);
}

.search-result__type, .bordered-box__title, .colored-box .point__title, .colored-box .point[data-has-text=true] .point__title, .why-us__title, .main .page__content h6, .section__raw h6 {
  font: 700 1.125rem/1.333 var(--font);
}

.search-menu__title, .main .page__content .big-letters, .main .page__content blockquote p, .section__raw .big-letters, .section__raw blockquote p, .section__blockquote, .big-letters {
  font: 300 clamp( 		1.5rem, 		0.9909502262rem + 2.1719457014vw, 		3rem 	)/1.125 var(--font);
}

.testimonial__quote > *:not(footer) {
  font: 300 clamp( 		1.25rem, 		0.9106334842rem + 1.4479638009vw, 		2.25rem 	)/1.125 var(--font);
}

.pagination .page-numbers, .pagination__link, .filters__link, .colored-box .point[data-has-text=false] .point__title, input[type=tel]::placeholder, input[type=text]::placeholder, input[type=search]::placeholder, input[type=email]::placeholder, input[type=submit], .field--acceptance .field__label, .field__label, .search-results__item, .search-results__more, .main .page__content, .main .page__content strong, .main .page__content table tr td, .section__raw strong, .section__raw table tr td {
  font: 400 clamp( 		1.25rem, 		1.165158371rem + 0.3619909502vw, 		1.5rem 	)/1.333 var(--font);
}

.testimonial__citation, .bordered-box__content, .filters__title, .article-card__category, .article-card__date, .article-card__excerpt, .colored-box .point__text, .why-us__content, .wpcf7-acceptance label span, textarea, input[type=tel], input[type=text], input[type=search], input[type=email], .field-list, .section__raw {
  font: 400 1.125rem/1.333 var(--font);
}

.field__label .hint, .menu__wrapper .submenu__list > .menu-item > .menu-item-link {
  font: 400 1rem/1.333 var(--font);
}

.testimonial__citation {
  font-weight: 700;
}

.industry-insights, .footer, .main .page {
  --padding-inline: clamp(
  	1rem,
  	-0.6179775281rem + 3.3707865169vw,
  	2.5rem
  );
  --vw-unit: 50%;
  padding-inline: max(var(--padding-inline), var(--vw-unit) - var(--container) / 2);
}
.field__label--visually-hidden, .skip-to-content:not(:focus), .industry-insights__title {
  clip: rect(0, 0, 0, 0);
  position: absolute;
  margin: -1px;
  border-width: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.main > :first-child {
  margin-block-start: clamp(
		4rem,
		2.6425339367rem + 5.7918552036vw,
		8rem
	);
}
.main .page__title {
  margin-block: clamp(
		3rem,
		1.9819004525rem + 4.3438914027vw,
		6rem
	);
  color: var(--white);
  text-align: center;
  overflow-wrap: break-word;
}
.main .page__content > :where(:not(:first-child)) {
  margin-block-start: clamp(
		1.5rem,
		1.3303167421rem + 0.7239819005vw,
		2rem
	);
}
.main .page__content:empty {
  display: none;
}
.main .page__content .white-letters {
  color: var(--black);
}
.main .page__content h2,
.main .page__content h3,
.main .page__content strong,
.main .page__content em,
.main .page__content blockquote {
  color: var(--black);
}
.main .page__content em {
  font-style: normal;
}
.main .page__content strong {
  font-weight: 700;
}
.main .page__content table tr td {
  background-color: var(--_highlight, var(--white));
  padding-inline: 1.5rem;
  padding-block: 1rem;
  color: var(--_foreground, var(--accent-shade));
}
.main .page__content table tr:nth-of-type(1) td {
  background-color: var(--_foreground, var(--accent-shade));
  color: var(--_highlight, var(--white));
}
.main .page__content table tr strong {
  color: inherit;
  font-weight: 700;
}
.main .page__content a {
  color: var(--white);
  text-decoration: underline;
}
.main .page__content a:hover {
  text-decoration: none;
}
.main .page__content h2 {
  text-transform: initial;
}
.main .page__content h3 {
  text-transform: initial;
}
.main .page__content h4,
.main .page__content h5 {
  text-transform: initial;
}
.main .page__content h5 {
  text-transform: initial;
}

.section {
  padding-block: clamp(
		4rem,
		2.6425339367rem + 5.7918552036vw,
		8rem
	);
}
.section__blockquote, .section__title, .section__cta {
  color: var(--_highlight, var(--white));
}
.section__graphic, .section__image {
  aspect-ratio: var(--ratio);
  width: min(var(--max-width), 100%);
}
@media (width < 768px) {
  .section__graphic, .section__image {
    width: min(var(--max-width), 70%);
  }
}
.section__raw > :where(:not(:first-child)) {
  margin-block-start: clamp(
		1.5rem,
		1.3303167421rem + 0.7239819005vw,
		2rem
	);
}
.section__raw:empty {
  display: none;
}
.section__raw .white-letters {
  color: var(--_highlight);
}
.section__raw h2,
.section__raw h3,
.section__raw strong,
.section__raw em,
.section__raw blockquote {
  color: var(--_highlight);
}
.section__raw em {
  font-style: normal;
}
.section__raw strong {
  font-weight: 700;
}
.section__raw table tr td {
  background-color: var(--_highlight, var(--white));
  padding-inline: 1.5rem;
  padding-block: 1rem;
  color: var(--_foreground, var(--accent-shade));
}
.section__raw table tr:nth-of-type(1) td {
  background-color: var(--_foreground, var(--accent-shade));
  color: var(--_highlight, var(--white));
}
.section__raw table tr strong {
  color: inherit;
  font-weight: 700;
}
.white-letters,
.big-letters {
  color: var(--_highlight, white);
}

.loading {
  background: url("../../images/loader.gif") no-repeat center;
  height: 9px;
}

.homelink {
  width: clamp( 		2.3rem, 		1.892760181rem + 1.7375565611vw, 		3.5rem 	);
  height: clamp( 		2.3rem, 		1.892760181rem + 1.7375565611vw, 		3.5rem 	);
  display: block;
  position: fixed;
  top: clamp(
		2rem,
		1.6606334842rem + 1.4479638009vw,
		3rem
	);
  left: 50%;
  /*justify-content: center;
  align-items: center;*/
  transform: translate(-50%, 0);
  z-index: var(--z-header);
}
.homelink svg {
  width: 100%;
  display: block;
}

.op-0 {
  opacity: 0;
}

.relative {
  position: relative;
}

.overflow {
  overflow: hidden;
}

.sticky-t {
  position: sticky;
  top: 0;
}

.menu {
  display: grid;
  position: fixed;
  grid-template-columns: auto min(72rem, 100%);
  grid-template-areas: "ghost menu";
  visibility: hidden;
  opacity: 0;
  z-index: var(--z-menu);
  inset: 0;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  pointer-events: none;
}
.menu--is-open {
  overflow: auto;
  pointer-events: auto;
}
.menu--is-open .menu__background {
  pointer-events: auto !important;
}
.menu__background {
  grid-area: menu;
  z-index: 2;
  width: min(72rem, 100%);
  height: 100%;
  color: var(--highlight);
}
.menu__wrapper {
  display: grid;
  grid-area: menu;
  align-content: start;
  align-self: start;
  justify-self: end;
  z-index: 3;
  padding: 4rem clamp(
		3rem,
		2.6606334842rem + 1.4479638009vw,
		4rem
	) 4rem clamp(
		1rem,
		-0.0180995475rem + 4.3438914027vw,
		4rem
	);
  width: min(48rem, 100%);
}
@media (width < 520px) {
  .menu__wrapper {
    gap: 0.5rem;
  }
}
.menu__ghost {
  grid-area: ghost;
}

.menu__wrapper .menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu__wrapper .menu__list--top {
  margin-block-start: clamp(
		1rem,
		0.8303167421rem + 0.7239819005vw,
		1.5rem
	);
}
@media (width < 1024px) {
  .menu__wrapper .menu__list--top {
    justify-self: end;
    text-align: right;
  }
}
.menu__wrapper .menu__list--bot {
  position: relative;
  justify-self: end;
  margin-block-start: clamp(
		1rem,
		0.8303167421rem + 0.7239819005vw,
		1.5rem
	);
  text-align: right;
}
.menu__wrapper .menu__list--bot::before {
  position: absolute;
  right: 0.5rem;
  bottom: -2.5rem;
  transform: scaleX(var(--line-width, 0));
  transform-origin: top right;
  transition: transform 0.2s ease-out;
  background: currentColor;
  width: 2rem;
  height: 0.375rem;
  pointer-events: none;
  content: "";
}
.menu:not(.menu--is-open) .menu__wrapper .menu__list--bot::before {
  content: none;
}

.menu__wrapper .menu__list > .menu-item:not(:first-child) {
  margin-block-start: 0.4em;
}
.menu__wrapper .submenu__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15ch, 1fr));
  align-items: end;
  gap: clamp(
		0.5rem,
		0.3303167421rem + 0.7239819005vw,
		1rem
	) 1.5rem;
  margin: 0;
  margin-block-start: clamp(
		1rem,
		0.8303167421rem + 0.7239819005vw,
		1.5rem
	);
  padding: 0;
  width: min(32rem, 100%);
  list-style-type: none;
}
.menu__wrapper .submenu__list > .menu-item > .menu-item-link {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
}
@media (width > 768px) {
  .menu__wrapper .submenu__list > .menu-item > .menu-item-link {
    display: block;
    font: 700 1.125rem/1.333 var(--font);
  }
}
.menu__wrapper .menu-item.current-page-item > .menu-item-link:before, .menu__wrapper .menu-item.current_page_item > .menu-item-link:before {
  background-size: 100% 100%;
}
.menu__wrapper .menu-item-link {
  display: inline-block;
  position: relative;
  /*transform: translateY(100%);
  opacity: 0;*/
  isolation: isolate;
  padding: 0.1rem 0.5rem;
}
.menu__wrapper .menu-item-link::before {
  position: absolute;
  transform-origin: left bottom;
  z-index: -1;
  transition: background-size 0.4s var(--easeInOutQuad);
  inset: 0;
  background: linear-gradient(var(--white), var(--white)) no-repeat left bottom/100% 0%;
  content: "";
}
.menu__wrapper .menu-item-link:focus::before, .menu__wrapper .menu-item-link:hover::before {
  background-size: 100% 100%;
}

/*.link-wrap{
	position: relative;
	display: inline-block;
}*/
.menu-toggler {
  --padding: clamp(
  	0.25rem,
  	0.165158371rem + 0.3619909502vw,
  	0.5rem
  );
  --line-height: clamp(
  	0.25rem,
  	0.2075791855rem + 0.1809954751vw,
  	0.375rem
  );
  --angle: -10deg;
  --gap: clamp(
  	0.125rem,
  	0.0995475113rem + 0.1085972851vw,
  	0.2rem
  );
  width: clamp( 		2rem, 		1.6606334842rem + 1.4479638009vw, 		3rem 	);
  height: clamp( 		2rem, 		1.6606334842rem + 1.4479638009vw, 		3rem 	);
  display: inline-flex;
  position: fixed;
  top: clamp(
		2rem,
		1.6606334842rem + 1.4479638009vw,
		3rem
	);
  right: clamp(
		1rem,
		-0.0180995475rem + 4.3438914027vw,
		4rem
	);
  z-index: var(--z-toggler);
  transition: transform 0.4s var(--easeInOutCubic);
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
  color: inherit;
}
.menu-toggler__icon {
  vertical-align: middle;
  margin: auto;
  width: 100%;
  height: auto;
}
.menu-toggler__icon-line {
  transform-origin: center;
  transition: all 0.25s var(--easeInOutBack);
}
@media (hover: hover) {
  .menu-toggler:hover:not(.menu-toggler--is-open) .menu-toggler__icon-line--top {
    transform: rotate(30deg) translate(-2px, -3px);
  }
  .menu-toggler:hover:not(.menu-toggler--is-open) .menu-toggler__icon-line--mid {
    transform: rotate(15deg) translate(-1px, -5px);
  }
  .menu-toggler:hover:not(.menu-toggler--is-open) .menu-toggler__icon-line--bot {
    transform: rotate(0deg) translate(-3px, -6px);
  }
}
.menu-toggler--is-open .menu-toggler__icon-line--top {
  transform: rotate(75deg) translate(4px, 8px);
}
.menu-toggler--is-open .menu-toggler__icon-line--mid {
  transform: rotate(15deg) translate(-1px, -5px);
  opacity: 0;
}
.menu-toggler--is-open .menu-toggler__icon-line--bot {
  transform: rotate(-45deg) translate(-3px, -18px);
}
.menu-toggler--is-open:hover {
  transform: rotate(1turn);
}

.footer {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  background-color: var(--black);
  padding-block: clamp(
		2.5rem,
		1.8212669683rem + 2.8959276018vw,
		4.5rem
	) clamp(
		2.5rem,
		1.9909502262rem + 2.1719457014vw,
		4rem
	);
  color: var(--white);
}
@media (width < 1048px) {
  .footer {
    display: grid;
    justify-content: center;
    gap: clamp(
		4rem,
		3.5604395604rem + 2.1978021978vw,
		5rem
	);
  }
}
@media (width <= 560px) {
  .footer {
    justify-items: center;
  }
}
@media (560px < width < 1048px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }
}
.footer__contact, .footer__homelink, .footer__social, .footer__newsletter {
  flex: 0 1 auto;
}
.footer__homelink {
  transform-origin: center;
  animation: rotate 38s linear forwards infinite;
  /*@media (width < 560px) {
  	justify-self: start;
  }*/
}
@media (width < 1048px) {
  .footer__homelink {
    grid-column: span 2;
    justify-self: start;
    order: -1;
    aspect-ratio: 1;
  }
}
.footer__homelink:focus {
  outline: 1px solid transparent;
}
.footer .trupp-logo {
  width: clamp(
		7rem,
		4.6244343891rem + 10.1357466063vw,
		14rem
	);
  height: auto;
}
@media (width < 560px) {
  .footer .contact {
    grid-column: span 2;
    width: 100%;
  }
}
.footer .contact__title {
  margin-block-end: 1em;
  text-transform: uppercase;
}
.footer .contact-info {
  display: grid;
  gap: 2em;
  font-style: normal;
}
.footer .contact-info__tel, .footer .contact-info__email, .footer .contact-info__address {
  display: grid;
  grid-template-columns: clamp(
		2rem,
		1.6606334842rem + 1.4479638009vw,
		3rem
	) auto;
  align-items: center;
  gap: clamp(
		1rem,
		0.6606334842rem + 1.4479638009vw,
		2rem
	);
}
.footer .contact-info__tel > svg, .footer .contact-info__email > svg, .footer .contact-info__address > svg {
  width: 100%;
  height: auto;
}
.footer .contact-info__email {
  transition: color 0.2s linear;
}
.footer .contact-info__email:focus, .footer .contact-info__email:hover {
  color: var(--highlight);
}
.footer .contact-info__email:focus {
  outline: 1px solid transparent;
}
.footer .social {
  display: grid;
  gap: clamp(
		1rem,
		0.8303167421rem + 0.7239819005vw,
		1.5rem
	);
}
@media (width <= 560px) {
  .footer .social {
    /*grid-template-columns: repeat(4, 1fr);*/
    display: flex;
    grid-column: span 2;
    justify-content: start;
    width: 100%;
  }
}
@media (560px < width < 1048px) {
  .footer .social {
    justify-self: end;
  }
}
.footer .social__link {
  display: inline-block;
  transition: color 0.2s linear;
}
.footer .social__link:focus, .footer .social__link:hover {
  color: var(--highlight);
}
.footer .social__link:focus {
  outline: 1px solid transparent;
}
.footer .social__link svg {
  width: 2rem;
  height: auto;
}
.footer .newsletter {
  width: min(25rem, 100%);
}
@media (width < 1048px) {
  .footer .newsletter {
    grid-column: span 2;
    width: 100%;
  }
}
.footer .newsletter__title {
  margin-block-end: 1em;
  text-transform: uppercase;
}
.footer__to-top {
  display: inline-flex;
  position: absolute;
  z-index: var(--z-controls);
  transition: background-size 0.4s ease;
  cursor: pointer;
  inset: clamp(
		2.5rem,
		1.8212669683rem + 2.8959276018vw,
		4.5rem
	) 0.5rem auto auto;
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 0px;
  padding: 0.5rem;
  inline-size: 3.5rem;
  color: var(--white);
}
@media (width >= 1400px) {
  .footer__to-top {
    display: none;
  }
}
.footer__to-top:focus, .footer__to-top:hover {
  background: linear-gradient(var(--white), var(--white)) no-repeat left bottom/100% 100%;
  color: var(--black);
}
.footer__to-top:focus {
  outline: 1px solid transparent;
}
.footer__to-top svg {
  margin: auto;
  width: 1.5rem;
  height: auto;
}

.industry-insights {
  position: relative;
  margin-block-start: clamp(
		2rem,
		-1.3936651584rem + 14.479638009vw,
		12rem
	);
  padding-block: clamp(
		4rem,
		1.963800905rem + 8.6877828054vw,
		10rem
	) clamp(
		4rem,
		3.3212669683rem + 2.8959276018vw,
		6rem
	);
}
.industry-insights .marquee {
  position: absolute;
  top: clamp(
		3rem,
		1.9819004525rem + 4.3438914027vw,
		6rem
	);
  left: 0;
  z-index: -1;
  width: 100%;
  height: auto;
}
.industry-insights .blog,
.industry-insights .cta {
  padding-block: clamp(
		3rem,
		1.9819004525rem + 4.3438914027vw,
		6rem
	);
}
@media (width < 1700px) {
  .industry-insights .blog,
  .industry-insights .cta {
    padding-right: 5vw;
  }
}
.industry-insights .blog {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding-inline: clamp(
		0rem,
		-4.7663551402rem + 4.4859813084vw,
		6rem
	);
}
@media (width < 1480px) {
  .industry-insights .blog {
    grid-template-columns: 1fr;
    place-items: center;
    gap: clamp(
		3rem,
		1.9819004525rem + 4.3438914027vw,
		6rem
	);
  }
}
.industry-insights .blog__image {
  aspect-ratio: var(--ratio);
  width: min(var(--max-width, 35rem), 90%);
  height: auto;
}
.industry-insights .blog__articles {
  width: min(60rem, 100%);
}
.industry-insights .blog .article-card:not(:first-of-type) {
  margin-block-start: 2rem;
}
.industry-insights .cta {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 0;
  padding-inline: clamp(
		1rem,
		-2.9719626168rem + 3.738317757vw,
		6rem
	);
}
@media (width < 1024px) {
  .industry-insights .cta {
    grid-template-columns: 1fr 2fr;
    gap: 8rem;
  }
}
@media (width < 768px) {
  .industry-insights .cta {
    grid-template-rows: max-content auto;
    grid-template-columns: 1fr;
    place-content: start;
  }
}
.industry-insights .cta__image {
  width: min(var(--max-width, 48rem), 100%);
  height: auto;
}
@media (width < 768px) {
  .industry-insights .cta__image {
    transform: translateX(-25%) rotate(90deg) scaleY(-1);
    transform-origin: center;
  }
}
.industry-insights .cta__header {
  justify-self: start;
}
.industry-insights .cta__title {
  margin-block-end: 0.5rem;
}
.industry-insights .cta__link {
  color: var(--white);
}

.search {
  display: grid;
  position: fixed;
  grid-template-columns: auto min(72rem, 100%);
  grid-template-areas: "ghost search";
  opacity: 0;
  z-index: var(--z-search);
  inset: 0;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  pointer-events: none;
}
.search--is-open {
  overflow: auto;
  pointer-events: auto;
}
.search--is-open .search__background {
  pointer-events: auto !important;
}
.search__background {
  grid-area: search;
  z-index: 2;
  width: min(72rem, 100%);
  height: 100%;
  color: var(--highlight);
}
.search__wrapper {
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-area: search;
  align-self: stretch;
  justify-self: end;
  z-index: 3;
  padding: 3rem clamp(
		2.5rem,
		1.9909502262rem + 2.1719457014vw,
		4rem
	);
  width: min(58rem, 90%);
}
@media (width < 520px) {
  .search__wrapper {
    gap: 0.5rem;
  }
}
.search__ghost {
  grid-area: ghost;
}

.search-form,
.search-results {
  align-self: start;
}

.search-results {
  margin-block-start: 2rem;
}
.search-results__item:not(:first-child) {
  margin-block-start: 0.5rem;
}
.search-results__more {
  margin-block-start: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.search-toggler {
  --padding: clamp(
  	0.25rem,
  	0.165158371rem + 0.3619909502vw,
  	0.5rem
  );
  --line-height: clamp(
  	0.25rem,
  	0.2075791855rem + 0.1809954751vw,
  	0.375rem
  );
  --angle: -10deg;
  --gap: clamp(
  	0.125rem,
  	0.0995475113rem + 0.1085972851vw,
  	0.2rem
  );
  width: clamp( 		2rem, 		1.8303167421rem + 0.7239819005vw, 		2.5rem 	);
  height: clamp( 		2rem, 		1.8303167421rem + 0.7239819005vw, 		2.5rem 	);
  display: inline-block;
  position: fixed;
  top: clamp(
		2rem,
		1.6606334842rem + 1.4479638009vw,
		3rem
	);
  right: clamp(
		1rem,
		-0.0180995475rem + 4.3438914027vw,
		4rem
	);
  opacity: 0;
  z-index: var(--z-toggler);
  transition: transform 0.4s var(--easeInOutCubic), opacity 0.3s linear 0.4s;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0 var(--padding);
  color: inherit;
}
.search-toggler__line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  transition: transform 0.25s var(--easeInOutBack);
  background-color: currentColor;
  width: 100%;
  height: var(--line-height);
}
.search-toggler__line--top {
  transform: translate(calc(-50% - var(--gap) / 0.75), calc(-50% - var(--gap) * 3)) rotate(calc(var(--angle) * 2));
}
.search-toggler__line--mid {
  transform: translate(calc(-50% - var(--gap) / 2.5), calc(-50% - var(--gap) / 6)) rotate(var(--angle));
}
.search-toggler__line--bot {
  transform: translate(-50%, calc(-50% + var(--gap) * 3));
}
.search-toggler:hover:not(.search-toggler--is-open) .search-toggler__line--top {
  transform: translate(-50%, calc(-50% - var(--gap) * 4));
}
.search-toggler:hover:not(.search-toggler--is-open) .search-toggler__line--mid {
  transform: translate(-50%, -50%);
}
.search-toggler:hover:not(.search-toggler--is-open) .search-toggler__line--bot {
  transform: translate(-50%, calc(-50% + var(--gap) * 4));
}
.search-toggler--is-open {
  opacity: 1;
}
.search-toggler--is-open .search-toggler__line--top {
  transform: translate(-50%, -50%) rotate(45deg);
}
.search-toggler--is-open .search-toggler__line--mid {
  opacity: 0;
}
.search-toggler--is-open .search-toggler__line--bot {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.search-toggler--is-open:hover {
  transform: rotate(1turn);
}

.search-menu {
  align-self: end;
  justify-self: end;
  margin-block-start: 2rem;
  width: min(18rem, 100%);
  text-align: right;
}
.search-menu__title {
  margin-block-end: 2rem;
}
.search-menu .search-menu__wrapper {
  position: relative;
  padding-block-end: 2.5rem;
  list-style: none;
}
.search-menu .search-menu__wrapper::before {
  position: absolute;
  right: 0.5rem;
  bottom: 0rem;
  transform: scaleX(var(--line-width, 0));
  transform-origin: top right;
  transition: transform 0.2s ease-out;
  background: currentColor;
  width: 2rem;
  height: 0.375rem;
  pointer-events: none;
  content: "";
}
.search:not(.search--is-open) .search-menu .search-menu__wrapper::before {
  content: none;
}

@media (width < 768px) {
  .search-menu .search-menu__wrapper {
    display: grid;
  }
}
.search-menu .search-menu__link {
  display: block;
  text-transform: uppercase;
}
.search-menu .search-menu__link:not(:first-child) {
  margin-block-start: 0.5rem;
}

.search-menu .menu-item-link,
.search-results .menu-item-link {
  display: inline-block;
  position: relative;
  transform: translateX(-0.5rem);
  transition: transform 0.4s var(--easeInOutQuad);
  /*transform: translateY(100%);
  	opacity: 0;*/
  isolation: isolate;
  padding: 0.1rem 0.5rem;
}
.search-menu .menu-item-link::before,
.search-results .menu-item-link::before {
  position: absolute;
  transform-origin: left bottom;
  z-index: -1;
  transition: background-size 0.4s var(--easeInOutQuad);
  inset: 0;
  background: linear-gradient(var(--white), var(--white)) no-repeat left bottom/100% 0%;
  content: "";
}
.search-menu .menu-item-link:focus, .search-menu .menu-item-link:hover,
.search-results .menu-item-link:focus,
.search-results .menu-item-link:hover {
  transform: translateX(0);
}
.search-menu .menu-item-link:focus::before, .search-menu .menu-item-link:hover::before,
.search-results .menu-item-link:focus::before,
.search-results .menu-item-link:hover::before {
  background-size: 100% 100%;
}

.form--newsletter {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
}
.form--newsletter .field:not(:first-child) {
  margin-block-start: 0;
}
.form--newsletter .field:not(.field--acceptance) {
  grid-column: span 2;
}
.form--newsletter .form__disclaimer {
  grid-column: span 2;
  text-align: justify;
}
.form--newsletter .form__disclaimer a {
  text-decoration: underline;
}
.form--newsletter .form__disclaimer a:hover {
  text-decoration: none;
}
.form--newsletter .field:focus-within, .form--newsletter .field:focus {
  color: var(--highlight);
}
.form--newsletter .input--submit {
  --_highlight: var(--white);
}
.form--newsletter .input--submit:hover {
  background: var(--highlight);
  color: var(--black);
}
.form--search {
  padding-right: 4rem;
}
.form--search .field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}
.form--search .field__label {
  flex: 1 auto clamp(
		12rem,
		9.963800905rem + 8.6877828054vw,
		18rem
	);
}
.form--search .field .input {
  flex-grow: 999;
  flex-basis: 0;
  min-inline-size: clamp(
		12rem,
		9.963800905rem + 8.6877828054vw,
		18rem
	);
}

.marquee {
  pointer-events: none;
  color: var(--white);
}
.marquee text {
  fill: currentColor;
  font-weight: 500;
  font-size: clamp(4.25rem, -4vw + 10rem, 6.25rem);
  letter-spacing: 0.2em;
  text-transform: lowercase;
}

button.link,
a.link {
  display: inline-flex;
  position: relative;
  align-items: center;
  gap: 0.875rem;
  transform: translateX(-1.5rem);
  transition: background-size 0.4s var(--easeInOutQuad) 0.15s, transform 0.4s var(--easeInOutQuad), color 0.4s linear;
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 0;
  isolation: isolate;
  padding: 0.9rem 1.6rem;
  line-height: 1;
  text-transform: lowercase;
  overflow-wrap: break-word;
}
button.link svg,
a.link svg {
  transition: transform 0.3s ease-in-out;
  width: 0.75em;
  height: auto;
}
button.link:focus, button.link:hover,
a.link:focus,
a.link:hover {
  transform: translateX(0);
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 100%;
  color: var(--white);
}
button.link:focus svg, button.link:hover svg,
a.link:focus svg,
a.link:hover svg {
  transform: translateX(0.5rem);
}
button.link:focus,
a.link:focus {
  outline: 1px solid transparent;
}
button.link[data-size=big],
a.link[data-size=big] {
  text-transform: uppercase;
  white-space: nowrap;
}
button.link[data-no-transform=true],
a.link[data-no-transform=true] {
  transform: translateX(0);
}

.service__link {
  padding-block: 0.4em;
}

.skip-to-content {
  position: fixed;
  left: 1rem;
  z-index: 9999;
  transition: background-size 0.2s ease-in-out;
  cursor: pointer;
  background: linear-gradient(var(--black), var(--black)) no-repeat left top/0px 100%;
  padding: 0.5rem;
  color: var(--white);
}
.skip-to-content:focus {
  outline: 1px solid transparent;
  background-size: 100% 100%;
}
.sticky-controls {
  display: grid;
  position: fixed;
  gap: 0.5rem;
  z-index: var(--z-controls);
  inset: auto 0.5rem 1rem auto;
}
@media (width < 1400px) {
  .sticky-controls {
    gap: 0;
  }
}
.sticky-controls__search, .sticky-controls__to-top {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-size 0.4s ease;
  cursor: pointer;
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 0px;
  padding: 0.5rem;
  color: var(--white);
}
.sticky-controls__search:focus, .sticky-controls__search:hover, .sticky-controls__to-top:focus, .sticky-controls__to-top:hover {
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 100%;
}
.sticky-controls__search:focus, .sticky-controls__to-top:focus {
  outline: 1px solid transparent;
}
.sticky-controls__search svg, .sticky-controls__to-top svg {
  width: 2rem;
  height: auto;
}
@media (width >= 1400px) {
  .sticky-controls__to-top {
    height: auto;
  }
  .sticky-controls__to-top .mobile {
    display: none;
  }
}
@media (width < 1400px) {
  .sticky-controls__to-top .desktop {
    display: none;
  }
}
.sticky-controls__search {
  margin: 0;
  border: 0;
}

label {
  cursor: pointer;
}
.field-list label {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--black);
  line-height: 1.1;
}

.wpcf7-acceptance label {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  color: var(--black);
  line-height: 1.1;
}
@media (width < 1024px) {
  .wpcf7-acceptance label {
    align-items: flex-start;
  }
}
.wpcf7-acceptance label span {
  font-weight: 400;
  text-transform: initial;
}
.wpcf7-acceptance label a {
  text-decoration: underline;
}
.wpcf7-acceptance label a:hover {
  text-decoration: none;
}

label input[type=checkbox],
label input[type=radio] {
  flex: 0 0 2.25rem;
}
label input[type=checkbox]:checked,
label input[type=radio]:checked {
  color: var(--_highlight, var(--white));
}
label input[type=checkbox]:checked ~ span,
label input[type=radio]:checked ~ span {
  color: var(--_highlight, var(--white));
}

.input--submit {
  display: block;
  border-radius: 0 !important;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}

textarea {
  margin: 0;
  border: 0.15em solid currentColor;
  border-radius: 0;
  background-color: transparent;
  padding: 1em;
  width: max(2rem, 100%);
  resize: vertical;
  color: inherit;
}

input {
  transition: all 0.3s ease-in-out;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  color: inherit;
  font: inherit;
}
input[type=search] {
  appearance: none;
}
input[type=tel], input[type=text], input[type=search], input[type=email] {
  display: flex;
  align-items: center;
  border-bottom: 0.15em solid currentColor;
  width: max(2rem, 100%);
  min-block-size: 2.25rem;
}
input[type=tel]:focus, input[type=text]:focus, input[type=search]:focus, input[type=email]:focus {
  outline: none;
}
input[type=tel]:focus::placeholder, input[type=text]:focus::placeholder, input[type=search]:focus::placeholder, input[type=email]:focus::placeholder {
  opacity: 0.5;
  color: currentColor;
}
input[type=tel]::placeholder, input[type=text]::placeholder, input[type=search]::placeholder, input[type=email]::placeholder {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  color: inherit;
  font-weight: 700;
  text-transform: uppercase;
}
input[type=checkbox], input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
input[type=checkbox] {
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  margin: 0;
  border: 0.15em solid currentColor;
}
input[type=checkbox]:after {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  inset: 0;
  content: "";
}
input[type=checkbox]:checked {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon line' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M21 11v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m21 5-9 9-4-4' data-name='primary'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon line' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M21 11v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m21 5-9 9-4-4' data-name='primary'/%3E%3C/svg%3E");
  background-color: currentColor;
}
input[type=submit] {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 0;
  background: var(--_highlight, var(--white));
  padding: 0.5rem 0.7em;
  color: var(--black);
  font-weight: 700;
  line-height: 1.15;
}
input[type=submit]:disabled {
  opacity: 0.5;
  pointer-events: none;
}
input[type=submit]:hover {
  background: var(--accent-shade, var(--white));
  color: var(--_highlight, var(--white));
}

.wpcf7-not-valid-tip {
  display: block;
  mix-blend-mode: difference;
  margin-block-start: 1em;
  background-color: rgba(255, 0, 0, 0.7);
  padding: 0.5rem 0.5rem;
  color: var(--white);
}

.field {
  display: grid;
}
.field--acceptance {
  display: flex;
  align-items: center;
  gap: 1rem;
  line-height: 1.1;
}
@media (width < 1024px) {
  .field--acceptance {
    align-items: flex-start;
  }
}
.field--acceptance .field__label {
  font-weight: 700;
  text-transform: initial;
}
.field:not(:first-child) {
  margin-block-start: 2.4rem;
}
.field__label {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}
.field__label--group {
  margin-block-end: 2rem;
}
.field__label .hint {
  color: var(--accent-shade);
  text-transform: initial;
}
.field-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  gap: clamp(
		1.5rem,
		1.3303167421rem + 0.7239819005vw,
		2rem
	);
  text-transform: uppercase;
}
.field-list > * {
  flex: 0 0 50%;
}
.field-group {
  display: block;
}

button[type=submit] {
  border: 0;
  border-radius: 0;
}

.red {
  color: var(--we-are, red);
}

.wpcf7-response-output {
  font-size: 24px;
  line-height: 1.2em;
  font-family: var(--font);
  font-weight: 550;
  margin-top: 2em;
  color: #fff;
  padding-left: 12px;
  padding-top: 0;
  border-left: solid 6px #fff;
}

.why-us {
  padding: clamp(
		2rem,
		1.3212669683rem + 2.8959276018vw,
		4rem
	);
  color: var(--_text, inherit);
}
.why-us__title {
  margin-block-end: 2em;
}
.why-us__content ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp( 		12rem, 		9.963800905rem + 8.6877828054vw, 		18rem 	), 1fr));
  gap: 2.25rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.why-us__content ul li {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}
.why-us__content ul li::before {
  width: 0.625rem;
  height: 0.625rem;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--_highlight);
  content: "";
}

.colored-box {
  /*==============================================
  =            COLORED BOX VARIATIONS            =
  ===============================================*/
  /*=====  End of COLORED BOX VARIATIONS  ======*/
}
.colored-box__title {
  margin-block-end: 1em;
  color: var(--_highlight, var(--accent));
}
@media (width > 768px) {
  [data-orientation=right] .colored-box__title {
    text-align: right;
  }
}

.colored-box__content {
  --_highlight: var(--white);
  background-color: var(--_foreground, var(--accent));
  padding: clamp(
		2rem,
		1.6606334842rem + 1.4479638009vw,
		3rem
	) clamp(
		1.5rem,
		0.8212669683rem + 2.8959276018vw,
		3.5rem
	);
  color: var(--_highlight, var(--white));
}
.colored-box .point {
  /*=============================================
  =            POINT VARIATIONS            =
  =============================================*/
  /*=====  End of POINT VARIATIONS  ======*/
}
.colored-box .point__title {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  line-height: 1.5rem;
}
.colored-box .point__title::before {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 1.5rem;
  transform: translateY(0.25rem);
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 27 26'%3E%3Cpath stroke='%23fff' stroke-miterlimit='10' stroke-width='4.16' d='M24.78 1.74 7.54 22.26 2 13.47'/%3E%3C/svg%3E");
}
.colored-box .point__text {
  margin-block-start: clamp(
		1rem,
		0.8303167421rem + 0.7239819005vw,
		1.5rem
	);
}
.colored-box .point[data-has-text=false] .point__title {
  color: var(--white);
  font-weight: 700;
}
.colored-box .point[data-has-text=true] .point__title {
  text-transform: uppercase;
}
.colored-box[data-columns="1"] .colored-box__content {
  display: grid;
  gap: 2.25rem;
}
.colored-box[data-columns="2"] .colored-box__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp( 		14rem, 		11.963800905rem + 8.6877828054vw, 		20rem 	), 1fr));
  gap: 2.25rem;
}

.article-card {
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  align-items: center;
  transition: background-color 0.4s linear;
  cursor: pointer;
  background-color: transparent;
  isolation: isolate;
  overflow: hidden;
}
@media (width < 580px) {
  .article-card {
    grid-template-columns: 1fr;
  }
}
.article-card > *:empty {
  display: none;
}
.article-card:focus-within, .article-card:hover {
  background-color: var(--accent-shade);
}
.article-card__picture {
  aspect-ratio: 1;
}
@media (width >= 580px) {
  .article-card__picture {
    width: 200px;
    height: auto;
  }
}
.article-card__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.article-card__header {
  padding: 1rem clamp(
		1rem,
		0.6606334842rem + 1.4479638009vw,
		2rem
	);
}
.article-card__category {
  margin-block-end: 1rem;
  font-weight: bold;
  line-height: 1.125;
  text-transform: uppercase;
}
.article-card__title {
  transition: color 0.4s linear;
  max-block-size: 5.625rem;
  overflow: hidden;
  color: var(--_highlight, white);
  line-height: 1.25;
  text-overflow: ellipsis;
}
@supports (-webkit-line-clamp: 1) {
  .article-card__title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-block-size: unset;
  }
}
.article-card__link::before {
  position: absolute;
  z-index: 2;
  inset: 0;
  content: "";
}
.article-card__link:focus {
  outline: 1px solid transparent;
}
.article-card__date, .article-card__excerpt {
  margin-block-start: 1rem;
  line-height: 1.125;
}
.article-card__excerpt {
  max-block-size: 2.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
@supports (-webkit-line-clamp: 1) {
  .article-card__excerpt {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-block-size: unset;
  }
}
.article-card__date {
  display: inline-block;
}
.article-card__cta {
  margin-block-start: 1rem;
  color: var(--_highlight, white);
}
.article-card--featured:hover {
  background-color: transparent;
}
.article-card--featured:hover .article-card__cta {
  transform: translateX(0);
  background: linear-gradient(var(--black), var(--black)) no-repeat 0 100%/100% 100%;
  color: var(--white);
}
@media (width >= 580px) {
  .article-card--featured .article-card__picture {
    width: clamp(
		10rem,
		7.2850678733rem + 11.5837104072vw,
		18rem
	);
    height: auto;
  }
}
.article-card--featured .article-card__header {
  padding-inline-end: 0;
}
.article-card--featured .article-card__title {
  width: 100%;
  max-block-size: 9rem;
  overflow: hidden;
  color: var(--_highlight, white);
  font: 700 clamp( 		1.5rem, 		1.1606334842rem + 1.4479638009vw, 		2.5rem 	)/1.15 var(--font);
  line-height: 1.2em;
  text-overflow: ellipsis;
}
@supports (-webkit-line-clamp: 1) {
  .article-card--featured .article-card__title {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-block-size: unset;
  }
}

.filters__title {
  border-block-end: 0.5rem solid;
  padding-block-end: 1.5rem;
  color: var(--_highlight);
  font-weight: 800;
}
.filters__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: clamp(
		0.75rem,
		0.665158371rem + 0.3619909502vw,
		1rem
	);
}
@media (width < 768px) {
  .filters__list {
    padding-block-start: 1rem;
  }
}
.filters__link {
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  border-block-end: 0.5rem solid transparent;
  padding-block: clamp(
		0.25rem,
		-0.1742081448rem + 1.8099547511vw,
		1.5rem
	) clamp(
		0rem,
		-0.5090497738rem + 2.1719457014vw,
		1.5rem
	);
  font-weight: 800;
  text-transform: lowercase;
}
.filters__link:hover {
  color: var(--_highlight);
}
.filters__link[aria-current] {
  border-color: transparent;
  color: var(--_highlight);
}

.pagination {
  display: flex;
  align-items: center;
}
.pagination__pages {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-inline: 2rem;
}
.pagination .page-numbers, .pagination__link {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s ease-in-out;
  font-weight: 800;
  text-transform: lowercase;
}
.pagination .page-numbers:hover, .pagination__link:hover {
  color: var(--_highlight, var(--white));
}
.pagination .page-numbers--prev, .pagination__link--prev {
  margin-inline-end: 1rem;
}
.pagination .page-numbers--next, .pagination__link--next {
  margin-inline-start: 1rem;
}
.pagination .page-numbers--prev, .pagination .page-numbers--next, .pagination__link--prev, .pagination__link--next {
  width: 2rem;
  height: 2rem;
}
.pagination .page-numbers--prev svg, .pagination .page-numbers--next svg, .pagination__link--prev svg, .pagination__link--next svg {
  width: 1.2em;
  height: 1.2em;
}
.pagination .page-numbers[aria-disabled], .pagination__link[aria-disabled] {
  opacity: 0.25;
  pointer-events: none;
}
.pagination .page-numbers[aria-current], .pagination__link[aria-current] {
  border-color: currentColor;
  color: var(--_highlight, var(--white));
}

.accordion {
  padding-top: 0;
}
.accordion__title {
  display: inline-flex;
  align-items: center;
  gap: 2rem;
  transition: background-size 0.3s ease-in-out;
  cursor: pointer;
  border: 0;
  background: linear-gradient(var(--_highlight), var(--_highlight)) no-repeat left bottom/0% 0.5rem;
  padding-block: 1rem;
  color: var(--_highlight);
  line-height: 1;
  text-transform: initial;
}
.accordion__title:hover {
  background: linear-gradient(var(--_highlight), var(--_highlight)) no-repeat left bottom/100% 0.5rem;
}
.accordion__title svg {
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  transform-origin: center;
}
.accordion__title[aria-expanded=false] {
  margin-block-end: 2.5rem;
}
.accordion__title[aria-expanded=true] {
  background: linear-gradient(var(--_highlight), var(--_highlight)) no-repeat left bottom/100% 0.5rem;
}
.accordion__title[aria-expanded=true] svg {
  transform: scaleY(-1);
}
.accordion__content {
  padding-block: clamp(
		2rem,
		1.4909502262rem + 2.1719457014vw,
		3.5rem
	);
}

.bordered-box {
  padding: clamp(
		2rem,
		1.3212669683rem + 2.8959276018vw,
		4rem
	);
  color: var(--_text, inherit);
}
.bordered-box__title {
  margin-block-end: 2em;
}
.bordered-box__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp( 		12rem, 		9.963800905rem + 8.6877828054vw, 		18rem 	), 1fr));
  gap: 2.25rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.bordered-box__content li {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}
.bordered-box__content li::before {
  width: 0.625rem;
  height: 0.625rem;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--_highlight);
  content: "";
}

.search-result {
  display: grid;
  position: relative;
  align-items: start;
  gap: 1rem;
  transform: translateX(-0.5rem);
  transition: background 0.4s var(--easeInOutQuad), transform 0.4s var(--easeInOutQuad);
  margin-block-start: 0;
  background: linear-gradient(hsla(0, 0%, 100%, 0.4), hsla(0, 0%, 100%, 0.4)) no-repeat left bottom/100% 1px;
  padding: 0.5rem;
}
@media (width > 550px) {
  .search-result {
    grid-template-columns: min-content 1fr;
  }
}
.search-result:not(:first-child) {
  margin-block-start: 1.25em;
}
@media (width > 550px) {
  .search-result:not(:first-child) {
    margin-block-start: 0;
  }
}
.search-result:hover {
  transform: translateX(0%);
  background: linear-gradient(hsla(0, 0%, 100%, 0.4), hsla(0, 0%, 100%, 0.4)) no-repeat left bottom/100% 100%;
}
.search-result:hover .search-result__link {
  --_highlight: var(--black);
}
.search-result__type {
  width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-result__link {
  transition: color 0.4s ease-in-out;
  color: var(--_highlight);
}
.search-result__link::before {
  position: absolute;
  inset: 0;
  content: "";
}

.testimonial__quote {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}
.testimonial__quote > *:not(footer) {
  font-weight: 400;
}
.testimonial__citation {
  margin-block-start: clamp(
		1.5rem,
		0.9909502262rem + 2.1719457014vw,
		3rem
	);
  color: var(--_foreground, var(--accent-shade, inherit));
}

.get-in-touch {
  position: fixed;
  z-index: var(--z-controls);
  inset: auto auto 5.75rem 0.5rem;
}
.get-in-touch-link {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-size 0.4s ease;
  cursor: pointer;
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 0px;
  padding: 0.5rem;
  width: 3.5rem;
  height: auto;
  color: var(--white);
}
.page-get-in-touch .get-in-touch-link {
  display: none;
}

@media (width < 1200px) {
  .get-in-touch-link {
    display: none;
  }
}
.get-in-touch-link:focus, .get-in-touch-link:hover {
  background: linear-gradient(var(--black), var(--black)) no-repeat left bottom/100% 100%;
}
.get-in-touch-link:focus {
  outline: 1px solid transparent;
}
.get-in-touch-link__icon {
  width: 2.5rem;
  height: auto;
}