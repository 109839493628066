@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.colored-box {
	&__title {
		@extend %h5;
		margin-block-end: 1em;
		color: v("_highlight", var(--accent));

		@at-root [data-orientation="right"] & {
			@media (width > 768px) {
				text-align: right;
			}
		}
	}

	&__content {
		--_highlight: var(--white);
		background-color: v(_foreground, var(--accent));
		padding: fluid-rem(2rem, 3rem) fluid-rem(1.5rem, 3.5rem);
		color: v("_highlight", var(--white));
	}

	.point {
		&__title {
			@extend %h6;
			display: flex;
			align-items: baseline;
			gap: 0.5rem;
			line-height: 1.5rem;

			&::before {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 1.5rem;
				transform: translateY(0.25rem);
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 27 26'%3E%3Cpath stroke='%23fff' stroke-miterlimit='10' stroke-width='4.16' d='M24.78 1.74 7.54 22.26 2 13.47'/%3E%3C/svg%3E");
			}

			// &:last-child {
			// 	@extend %h5;
			// 	text-transform: none;
			// }
		}

		&__text {
			@extend %pl;
			margin-block-start: fluid-rem(1rem, 1.5rem);
		}

		/*=============================================
		=            POINT VARIATIONS            =
		=============================================*/

		&[data-has-text="false"] {
			.point__title {
				@extend %pxl;
				color: var(--white);
				font-weight: 700;
			}
		}

		&[data-has-text="true"] {
			.point__title {
				@extend %h6;
				text-transform: uppercase;
			}
		}

		/*=====  End of POINT VARIATIONS  ======*/
	}

	/*==============================================
	=            COLORED BOX VARIATIONS            =
	===============================================*/

	&[data-columns="1"] {
		.colored-box__content {
			display: grid;
			gap: 2.25rem;
		}
	}

	&[data-columns="2"] {
		.colored-box__content {
			display: grid;
			grid-template-columns: repeat(
				auto-fit,
				minmax(#{fluid-rem(14rem, 20rem)}, 1fr)
			);
			gap: 2.25rem;
		}
	}

	/*=====  End of COLORED BOX VARIATIONS  ======*/
}
