@use "./functions" as *;

%h1 {
	font: 500 fluid-rem(3.25rem, 6.25rem) / 1 v("font");
	text-transform: uppercase;
}

%h2 {
	font: 700 fluid-rem(2rem, 4.5rem) / 1.125 v("font");
	text-transform: uppercase;
}

%h3 {
	font: 700 fluid-rem(1.5rem, 3rem) / 1.25 v("font");
	text-transform: uppercase;
}

%h4 {
	font: 400 fluid-rem(1.25rem, 2.25rem) / 1.333 v("font");
}

%h5 {
	font: 700 fluid-rem(1.125rem, 1.5rem) / 1.333 v("font");
}

%h6 {
	font: 700 1.125rem / 1.333 v("font");
}

%bq {
	font: 300 fluid-rem(1.5rem, 3rem) / 1.125 v("font");
}

%bqt {
	font: 300 fluid-rem(1.25rem, 2.25rem) / 1.125 v("font");
}

%pxl {
	font: 400 fluid-rem(1.25rem, 1.5rem) / 1.333 v("font");
}

%pl {
	font: 400 1.125rem / 1.333 v("font");
}

%p {
	font: 400 1rem / 1.333 v("font");
}

%normal {
	font-weight: 400;
}

%bold {
	font-weight: 700;
}
