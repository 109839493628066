@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

label {
	cursor: pointer;

	@at-root .field-list & {
		display: flex;
		align-items: center;
		gap: 1rem;
		color: v("black");
		line-height: 1.1;
	}

	@at-root .wpcf7-acceptance & {
		display: flex;
		align-items: flex-start;
		gap: 1rem;
		color: v("black");
		line-height: 1.1;

		@media (width < 1024px) {
			align-items: flex-start;
		}

		span {
			@extend %pl;
			font-weight: 400;
			text-transform: initial;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		flex: 0 0 2.25rem;

		&:checked {
			color: v(_highlight, var(--white));

			~ span {
				color: v(_highlight, var(--white));
			}
		}
	}
}

.input--submit{
	display: block;
	border-radius: 0!important;
	-webkit-border-radius:0;
	-webkit-appearance:none;
}

textarea {
	@extend %pl;
	margin: 0;
	//border: 0;
	border: 0.15em solid currentColor;
	border-radius: 0;
	background-color: transparent;
	padding: 1em;
	width: max(2rem, 100%);
	resize: vertical;
	color: inherit;
}

input {
	transition: all 0.3s ease-in-out;
	margin: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	padding: 0;
	color: inherit;
	font: inherit;

	&[type="search"] {
		appearance: none;
	}

	&[type="tel"],
	&[type="text"],
	&[type="search"],
	&[type="email"] {
		@extend %pl;
		display: flex;
		align-items: center;
		border-bottom: 0.15em solid currentColor;
		width: max(2rem, 100%);
		min-block-size: 2.25rem;

		&:focus {
			outline: none;

			&::placeholder {
				opacity: 0.5;
				color: currentColor;
			}
		}

		&::placeholder {
			@extend %pxl;
			display: block;
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
			color: inherit;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&[type="checkbox"],
	&[type="radio"] {
		-webkit-appearance: none;
		appearance: none;
		cursor: pointer;
	}

	&[type="checkbox"] {
		@include size(2.25rem);
		position: relative;
		transition: background-color 0.3s ease-in-out;
		margin: 0;
		border: 0.15em solid currentColor;

		&:after {
			display: flex;
			position: absolute;
			justify-content: center;
			align-items: center;
			inset: 0;
			content: "";
			// font-size: 2em;
		}

		&:checked {
			-webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon line' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M21 11v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m21 5-9 9-4-4' data-name='primary'/%3E%3C/svg%3E");
			mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon line' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M21 11v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m21 5-9 9-4-4' data-name='primary'/%3E%3C/svg%3E");
			background-color: currentColor;

			// &:after {
			// 	// content: "✓";
			// 	content: "";
			// 	color: var(--accent);
			// }
		}
	}

	&[type="submit"] {
		@extend %pxl;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		border-radius: 0;
		background: v(_highlight, var(--white));
		padding: 0.5rem 0.7em;
		color: v("black");
		font-weight: 700;
		line-height: 1.15;

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		&:hover {
			background: v(accent-shade, var(--white));
			color: v(_highlight, var(--white));
		}
	}
}

.wpcf7-not-valid-tip {
	display: block;
	mix-blend-mode: difference;
	margin-block-start: 1em;
	background-color: c.adjust(red, $alpha: -0.3);
	padding: 0.5rem 0.5rem;
	color: v("white");
}

.field {
	display: grid;

	&--acceptance {
		display: flex;
		align-items: center;
		gap: 1rem;
		line-height: 1.1;

		@media (width < 1024px) {
			align-items: flex-start;
		}

		.field__label {
			@extend %pxl;
			font-weight: 700;
			text-transform: initial;
		}
	}

	&:not(:first-child) {
		margin-block-start: 2.4rem;
	}

	&__label {
		@extend %pxl;
		display: block;
		font-weight: 700;
		text-transform: uppercase;

		&--group {
			margin-block-end: 2rem;
		}

		&--visually-hidden {
			@extend %sr-only;
		}

		.hint {
			@extend %p;
			color: v("accent-shade");
			text-transform: initial;
		}
	}

	&-list {
		@extend %pl;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
		gap: fluid-rem(1.5rem, 2rem);
		text-transform: uppercase;

		> * {
			flex: 0 0 50%;
		}
	}

	&-group {
		display: block;
	}
}

button[type="submit"] {
	border: 0;
	border-radius: 0;
}

.red {
	color: var(--we-are, red);
}


.wpcf7-response-output{
	font-size: 24px;
	line-height: 1.2em;
	font-family: var(--font);
	font-weight: 550;
	margin-top: 2em;
	color: #fff;
	padding-left: 12px;
	padding-top: 0;
	border-left: solid 6px #fff;
}