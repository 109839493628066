@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.search {
	display: grid;
	position: fixed;
	grid-template-columns: auto min(72rem, 100%);
	grid-template-areas: "ghost search";
	// visibility: hidden;
	opacity: 0;
	z-index: v("z-search");
	inset: 0;
	height: 100vh;
	height: 100dvh;
	overflow: hidden;
	pointer-events: none;

	&--is-open {
		overflow: auto;
		pointer-events: auto;

		.search__background {
			pointer-events: auto !important;
		}
	}

	&__background {
		grid-area: search;
		z-index: 2;
		width: min(72rem, 100%);
		height: 100%;
		color: v("highlight");
	}

	&__wrapper {
		display: grid;
		grid-template-rows: min-content auto min-content;
		grid-area: search;
		align-self: stretch;
		justify-self: end;
		z-index: 3;
		padding: 3rem fluid-rem(2.5rem, 4rem);
		width: min(58rem, 90%);

		@media (width < 520px) {
			gap: 0.5rem;
		}
	}

	&__ghost {
		grid-area: ghost;
	}
}

.search-form,
.search-results {
	align-self: start;
}

.search-results {
	margin-block-start: 2rem;

	&__item {
		@extend %pxl;

		&:not(:first-child) {
			margin-block-start: 0.5rem;
		}
	}

	&__more {
		@extend %pxl;
		margin-block-start: 2rem;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.search-toggler {
	--padding: #{fluid-rem(0.25rem, 0.5rem)};
	--line-height: #{fluid-rem(0.25rem, 0.375rem)};
	--angle: -10deg;
	--gap: #{fluid-rem(0.125rem, 0.2rem)};
	@include size(#{fluid-rem(2rem, 2.5rem)});
	display: inline-block;
	position: fixed;
	top: fluid-rem(2rem, 3rem);
	right: fluid-rem(1rem, 4rem);
	opacity: 0;
	z-index: v("z-toggler");
	transition: transform 0.4s v("easeInOutCubic"), opacity 0.3s linear 0.4s;
	cursor: pointer;
	border: 0;
	background: transparent;
	padding: 0 v("padding");
	color: inherit;

	&__line {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 50% 50%;
		transition: transform 0.25s v("easeInOutBack");
		background-color: currentColor;
		width: 100%;
		height: v("line-height");

		&--top {
			transform: translate(
					calc(-50% - (var(--gap) / 0.75)),
					calc(-50% - (var(--gap) * 3))
				)
				rotate(calc(var(--angle) * 2));
		}

		&--mid {
			transform: translate(
					calc(-50% - (var(--gap) / 2.5)),
					calc(-50% - (var(--gap) / 6))
				)
				rotate(var(--angle));
		}

		&--bot {
			transform: translate(-50%, calc(-50% + (var(--gap) * 3)));
		}
	}

	&:hover:not(.search-toggler--is-open) {
		.search-toggler__line--top {
			transform: translate(-50%, calc(-50% - (var(--gap) * 4)));
		}

		.search-toggler__line--mid {
			transform: translate(-50%, -50%);
		}

		.search-toggler__line--bot {
			transform: translate(-50%, calc(-50% + (var(--gap) * 4)));
		}
	}

	&--is-open {
		opacity: 1;

		.search-toggler__line {
			&--top {
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&--mid {
				opacity: 0;
			}

			&--bot {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}

		&:hover {
			transform: rotate(1turn);
		}
	}
}

.search-menu {
	align-self: end;
	justify-self: end;
	margin-block-start: 2rem;
	width: min(18rem, 100%);
	text-align: right;

	&__title {
		@extend %bq;
		margin-block-end: 2rem;
	}

	.search-menu__wrapper {
		position: relative;
		padding-block-end: 2.5rem;
		list-style: none;

		&::before {
			position: absolute;
			right: 0.5rem;
			bottom: 0rem;
			transform: scaleX(var(--line-width, 0));
			transform-origin: top right;
			transition: transform 0.2s ease-out;
			background: currentColor;
			width: 2rem;
			height: 0.375rem;
			pointer-events: none;
			content: "";

			@at-root .search:not(.search--is-open) & {
				content: none;
			}
		}

		@media (width < 768px) {
			display: grid;
		}
	}

	.search-menu__link {
		@extend %h5;
		display: block;
		text-transform: uppercase;

		&:not(:first-child) {
			margin-block-start: 0.5rem;
		}
	}
}

.search-menu,
.search-results {
	.menu-item-link {
		display: inline-block;
		position: relative;
		transform: translateX(-0.5rem);
		transition: transform 0.4s v("easeInOutQuad");
		/*transform: translateY(100%);
			opacity: 0;*/
		isolation: isolate;
		padding: 0.1rem 0.5rem;

		&::before {
			position: absolute;
			transform-origin: left bottom;
			z-index: -1;
			transition: background-size 0.4s v("easeInOutQuad");
			inset: 0;
			background: linear-gradient(var(--white), var(--white)) no-repeat
				left bottom / 100% 0%;
			content: "";
		}

		&:focus,
		&:hover {
			transform: translateX(0);

			&::before {
				background-size: 100% 100%;
			}
		}
	}
}
