@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.accordion {
	padding-top: 0;

	&__title {
		@extend %h3;
		display: inline-flex;
		align-items: center;
		gap: 2rem;
		transition: background-size 0.3s ease-in-out;
		cursor: pointer;
		border: 0;
		background: linear-gradient(v(_highlight), v(_highlight)) no-repeat left
			bottom / 0% 0.5rem;
		padding-block: 1rem;
		color: v(_highlight);
		line-height: 1;
		text-transform: initial;

		&:hover {
			background: linear-gradient(v(_highlight), v(_highlight)) no-repeat
				left bottom / 100% 0.5rem;
		}

		svg {
			@include size(2.5rem);
			flex: 0 0 2.5rem;
			transform-origin: center;
		}

		&[aria-expanded="false"] {
			margin-block-end: 2.5rem;
		}

		&[aria-expanded="true"] {
			background: linear-gradient(v(_highlight), v(_highlight)) no-repeat
				left bottom / 100% 0.5rem;

			svg {
				transform: scaleY(-1);
			}
		}
	}

	&__content {
		padding-block: fluid-rem(2rem, 3.5rem);
	}
}
