@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.footer {
	@extend %container;
	display: flex;
	position: relative;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
	background-color: v("black");
	padding-block: fluid-rem(2.5rem, 4.5rem) fluid-rem(2.5rem, 4rem);
	color: v("white");

	@media (width < 1048px) {
		display: grid;
		justify-content: center;
		// justify-items: center;
		// align-items: start;
		gap: fluid-rem(4rem, 5rem, 320 1048);
	}

	@media (width <= 560px) {
		justify-items: center;
	}

	@media (560px < width < 1048px) {
		grid-template-columns: repeat(2, 1fr);
	}

	&__contact,
	&__homelink,
	&__social,
	&__newsletter {
		flex: 0 1 auto;
	}

	&__homelink {
		transform-origin: center;
		animation: rotate 38s linear forwards infinite;

		@media (width < 1048px) {
			grid-column: span 2;
			justify-self: start;
			order: -1;
			aspect-ratio: 1;
		}

		/*@media (width < 560px) {
			justify-self: start;
		}*/

		&:focus {
			outline: 1px solid transparent;
		}
	}

	.trupp-logo {
		width: fluid-rem(7rem, 14rem);
		height: auto;
	}

	.contact {
		@media (width < 560px) {
			grid-column: span 2;
			width: 100%;
		}

		&__title {
			@extend %h4;
			margin-block-end: 1em;
			// grid-column: span 2;
			text-transform: uppercase;
		}

		&-info {
			display: grid;
			gap: 2em;
			font-style: normal;

			&__tel,
			&__email,
			&__address {
				display: grid;
				grid-template-columns: fluid-rem(2rem, 3rem) auto;
				align-items: center;
				gap: fluid-rem(1rem, 2rem);

				> svg {
					width: 100%;
					height: auto;
				}
			}

			&__email {
				transition: color 0.2s linear;

				&:focus,
				&:hover {
					color: v("highlight");
				}

				&:focus {
					outline: 1px solid transparent;
				}
			}
		}
	}

	.social {
		display: grid;
		gap: fluid-rem(1rem, 1.5rem);

		@media (width <= 560px) {
			/*grid-template-columns: repeat(4, 1fr);*/
			display: flex;
			grid-column: span 2;
			justify-content: start;
			//width: fit-content;
			width: 100%;
		}

		@media (560px < width < 1048px) {
			justify-self: end;
		}

		&__link {
			display: inline-block;
			transition: color 0.2s linear;

			&:focus,
			&:hover {
				color: v("highlight");
			}

			&:focus {
				outline: 1px solid transparent;
			}

			svg {
				width: 2rem;
				height: auto;
			}
		}
	}

	.newsletter {
		width: min(25rem, 100%);

		@media (width < 1048px) {
			grid-column: span 2;
			width: 100%;
		}

		&__title {
			@extend %h4;
			margin-block-end: 1em;
			text-transform: uppercase;
		}
	}

	&__to-top {
		display: inline-flex;
		position: absolute;
		z-index: v("z-controls");
		transition: background-size 0.4s ease;
		cursor: pointer;
		inset: fluid-rem(2.5rem, 4.5rem) 0.5rem auto auto;
		background: linear-gradient(var(--black), var(--black)) no-repeat left
			bottom / 100% 0px;
		padding: 0.5rem;
		inline-size: 3.5rem;
		color: v("white");

		@media (width >= 1400px) {
			display: none;
		}

		&:focus,
		&:hover {
			background: linear-gradient(var(--white), var(--white)) no-repeat
				left bottom / 100% 100%;
			color: v("black");
		}

		&:focus {
			outline: 1px solid transparent;
		}

		svg {
			margin: auto;
			width: 1.5rem;
			height: auto;
		}
	}
}
