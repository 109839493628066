@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.homelink {
	@include size(#{fluid-rem(2.3rem, 3.5rem)});
	//width: 52px;
	display: block;
	position: fixed;
	top: fluid-rem(2rem, 3rem);
	left: 50%;
	/*justify-content: center;
	align-items: center;*/
	transform: translate(-50%, 0);
	z-index: v("z-header");

	svg {
		width: 100%;
		display: block;
		//height: auto;
	}
}

.op-0 {
	opacity: 0;
}

.relative {
	position: relative;
}

.overflow {
	overflow: hidden;
}

.sticky-t {
	position: sticky;
	top: 0;
}
