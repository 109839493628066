@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.form {
	&--newsletter {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 2rem;

		.field:not(:first-child) {
			margin-block-start: 0;
		}

		.field:not(.field--acceptance) {
			grid-column: span 2;
		}

		.form__disclaimer {
			grid-column: span 2;
			text-align: justify;

			a {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.field {
			&:focus-within,
			&:focus {
				color: var(--highlight);
			}
		}

		.input--submit {
			--_highlight: var(--white);

			&:hover {
				background: var(--highlight);
				color: var(--black);
			}
		}
	}

	&--search {
		padding-right: 4rem;

		.field {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 2rem;

			&__label {
				@extend %h3;
				flex: 1 auto fluid-rem(12rem, 18rem);
				// width: fit-content;
				// white-space: nowrap;
			}

			.input {
				flex-grow: 999;
				flex-basis: 0;
				// flex: 1 1 auto;
				min-inline-size: fluid-rem(12rem, 18rem);
			}
		}
	}
}
