@use "./colors" as *;
@use "./easings" as *;
@use "./settings" as *;

// Project Variables

:root {
	@each $name, $color in $colors {
		--#{$name}: #{$color};
	}

	@each $name, $easing in $easings {
		--#{$name}: #{$easing};
	}

	// Font
	--font: effra, sans-serif;

	// Containers
	--container: #{$container}px;

	// @media (width < 1440px) {
	// 	--container: #{$container-md}px;
	// }

	// @media (width < 1024px) {
	// 	--container: #{$container-sm}px;
	// }

	// z Indeces
	--z-header: 1000;
	--z-toggler: 1100;
	--z-menu: 1050;
	--z-search: 2000;
	--z-search-toggler: 2050;
	--z-controls: 900;
}
