@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.menu {
	display: grid;
	position: fixed;
	grid-template-columns: auto min(72rem, 100%);
	grid-template-areas: "ghost menu";
	visibility: hidden;
	opacity: 0;
	z-index: v("z-menu");
	inset: 0;
	height: 100vh;
	height: 100dvh;
	overflow: hidden;
	pointer-events: none;

	&--is-open {
		overflow: auto;
		pointer-events: auto;

		.menu__background {
			pointer-events: auto !important;
		}
	}

	&__background {
		grid-area: menu;
		z-index: 2;
		width: min(72rem, 100%);
		height: 100%;
		color: v("highlight");
	}

	&__wrapper {
		display: grid;
		grid-area: menu;
		align-content: start;
		align-self: start;
		justify-self: end;
		z-index: 3;
		padding: 4rem fluid-rem(3rem, 4rem) 4rem fluid-rem(1rem, 4rem);
		width: min(48rem, 100%);

		@media (width < 520px) {
			gap: 0.5rem;
		}
	}

	&__ghost {
		grid-area: ghost;
		// background-color: green;
		// grid-area: 1 / 1 / span 1 / span 2;
	}
}

.menu__wrapper {
	.menu__list {
		margin: 0;
		padding: 0;
		list-style: none;

		&--top {
			margin-block-start: fluid-rem(1rem, 1.5rem);

			@media (width < 1024px) {
				justify-self: end;
				text-align: right;
			}
		}

		&--bot {
			position: relative;
			justify-self: end;
			margin-block-start: fluid-rem(1rem, 1.5rem);
			text-align: right;

			&::before {
				position: absolute;
				right: 0.5rem;
				bottom: -2.5rem;
				transform: scaleX(var(--line-width, 0));
				transform-origin: top right;
				transition: transform 0.2s ease-out;
				background: currentColor;
				width: 2rem;
				height: 0.375rem;
				pointer-events: none;
				content: "";

				@at-root .menu:not(.menu--is-open) & {
					content: none;
				}
			}
		}

		> .menu-item {
			&:not(:first-child) {
				//margin-block-start: fluid-rem(1rem, 1.5rem);
				margin-block-start: 0.4em;
			}

			> .menu-item-link {
				@extend %h3;
			}
		}
	}

	.submenu__list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(15ch, 1fr));
		align-items: end;
		gap: fluid-rem(0.5rem, 1rem) 1.5rem;
		margin: 0;
		margin-block-start: fluid-rem(1rem, 1.5rem);
		padding: 0;
		width: min(32rem, 100%);
		list-style-type: none;

		> .menu-item {
			> .menu-item-link {
				@extend %p;
				display: inline-block;
				font-weight: 700;
				text-transform: uppercase;

				@media (width > 768px) {
					display: block;
					font: 700 1.125rem / 1.333 v("font");
				}
			}
		}
	}

	.menu-item {
		&.current-page-item,
		&.current_page_item {
			& > .menu-item-link {
				&:before {
					background-size: 100% 100%;
				}
			}
		}
	}

	.menu-item-link {
		display: inline-block;
		position: relative;
		/*transform: translateY(100%);
	opacity: 0;*/
		isolation: isolate;
		padding: 0.1rem 0.5rem;

		&::before {
			position: absolute;
			transform-origin: left bottom;
			z-index: -1;
			transition: background-size 0.4s v("easeInOutQuad");
			inset: 0;
			background: linear-gradient(var(--white), var(--white)) no-repeat
				left bottom / 100% 0%;
			content: "";
		}

		&:focus,
		&:hover {
			&::before {
				background-size: 100% 100%;
			}
		}
	}
}
/*.link-wrap{
	position: relative;
	display: inline-block;
}*/

.menu-toggler {
	--padding: #{fluid-rem(0.25rem, 0.5rem)};
	--line-height: #{fluid-rem(0.25rem, 0.375rem)};
	--angle: -10deg;
	--gap: #{fluid-rem(0.125rem, 0.2rem)};
	// @include size(#{fluid-rem(2rem, 2.5rem)});
	@include size(#{fluid-rem(2rem, 3rem)});
	display: inline-flex;
	position: fixed;
	top: fluid-rem(2rem, 3rem);
	right: fluid-rem(1rem, 4rem);
	z-index: v("z-toggler");
	transition: transform 0.4s v("easeInOutCubic");
	cursor: pointer;
	// outline: 2px solid red;
	border: 0;
	background: transparent;
	padding: 0;
	// padding: 0 v("padding");
	color: inherit;
	// overflow: hidden;

	&__icon {
		vertical-align: middle;
		margin: auto;
		// outline: 1px solid red;
		width: 100%;
		height: auto;

		&-line {
			transform-origin: center;
			transition: all 0.25s v("easeInOutBack");
		}
	}

	// &__line {
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%, -50%);
	// 	transform-origin: 50% 50%;
	// 	transition: transform 0.25s v("easeInOutBack");
	// 	background-color: currentColor;
	// 	width: 100%;
	// 	height: v("line-height");

	// 	&--top {
	// 		transform: translate(
	// 				calc(-50% - (var(--gap) / 0.75)),
	// 				calc(-50% - (var(--gap) * 3))
	// 			)
	// 			rotate(calc(var(--angle) * 2));
	// 	}

	// 	&--mid {
	// 		transform: translate(
	// 				calc(-50% - (var(--gap) / 2.5)),
	// 				calc(-50% - (var(--gap) / 6))
	// 			)
	// 			rotate(var(--angle));
	// 	}

	// 	&--bot {
	// 		transform: translate(-50%, calc(-50% + (var(--gap) * 3)));
	// 	}
	// }

	@media (hover: hover) {
		&:hover:not(.menu-toggler--is-open) {
			// .menu-toggler__line--top {
			// 	transform: translate(-50%, calc(-50% - (var(--gap) * 4)));
			// }

			// .menu-toggler__line--mid {
			// 	transform: translate(-50%, -50%);
			// }

			// .menu-toggler__line--bot {
			// 	transform: translate(-50%, calc(-50% + (var(--gap) * 4)));
			// }

			.menu-toggler__icon-line--top {
				transform: rotate(30deg) translate(-2px, -3px);
			}
			.menu-toggler__icon-line--mid {
				transform: rotate(15deg) translate(-1px, -5px);
			}
			.menu-toggler__icon-line--bot {
				transform: rotate(0deg) translate(-3px, -6px);
			}
		}
	}

	&--is-open {
		// .menu-toggler__line {
		// 	// transform-origin: 50% 50%;

		// 	&--top {
		// 		transform: translate(-50%, -50%) rotate(45deg);
		// 	}

		// 	&--mid {
		// 		opacity: 0;
		// 	}

		// 	&--bot {
		// 		transform: translate(-50%, -50%) rotate(-45deg);
		// 	}
		// }

		.menu-toggler__icon-line--top {
			transform: rotate(75deg) translate(4px, 8px);
		}
		.menu-toggler__icon-line--mid {
			transform: rotate(15deg) translate(-1px, -5px);
			opacity: 0;
		}
		.menu-toggler__icon-line--bot {
			transform: rotate(-45deg) translate(-3px, -18px);
		}

		&:hover {
			transform: rotate(1turn);
		}
	}
}
