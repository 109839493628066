@font-face {
	font-style: normal;
	font-weight: 300;
	src: url("./fonts/Effra_W_Lt.woff2") format("woff2");
	font-family: effra;
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/Effra_W_Rg.woff2") format("woff2");
	font-family: effra;
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 500;
	src: url("./fonts/Effra_W_Md.woff2") format("woff2");
	font-family: effra;
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 700;
	src: url("./fonts/Effra_W_Bd.woff2") format("woff2");
	font-family: effra;
	font-display: swap;
}

// @font-face {
// 	font-style: normal;
// 	font-weight: 100 900;
// 	src: url("./fonts/EffraVF_Trial_Wght.ttf") format("truetype-variations");
// 	font-family: "Effra";
// 	font-display: swap;
// }
