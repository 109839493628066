@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.testimonial {
	&__quote {
		display: flex;
		flex-direction: column;
		align-items: space-between;

		> *:not(footer) {
			@extend %bqt;
			font-weight: 400;
		}
	}

	&__citation {
		@extend %pl;
		@extend %bold;
		margin-block-start: fluid-rem(1.5rem, 3rem);
		color: v(_foreground, var(--accent-shade, inherit));
	}
}
