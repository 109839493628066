@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.marquee {
	pointer-events: none;
	color: v("white");

	text {
		fill: currentColor;
		font-weight: 500;
		font-size: clamp(4.25rem, -4vw + 10rem, 6.25rem);
		letter-spacing: 0.2em;
		text-transform: lowercase;
	}
}
