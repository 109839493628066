@use "./functions" as *;
@use "./typographic-elements" as *;

// Aspect Ratio
@mixin ar($w: 16, $h: 9, $child: true) {
	aspect-ratio: #{$w} / #{$h};

	@if $child {
		display: grid;

		> * {
			grid-area: 1 / -1;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	@supports not (aspect-ratio: 1 / 1) {
		position: relative;

		&::before {
			display: block;
			padding-top: calc((#{$h} / #{$w}) * 100%);
			width: 100%;
			content: "";
		}

		@if $child {
			> * {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				object-fit: cover;
				object-position: center;
			}
		}
	}
}

@mixin size($w: 1rem, $h: $w) {
	width: $w;
	height: $h;
}

// Full background with clipping
@mixin fullBgClip($c) {
	/* clip only the top and bottom part of it */
	clip-path: inset(0 -100vmax);
	/* a big box-shadow */
	box-shadow: 0 0 0 100vmax $c;
	background: $c;
}

// Full background with border-image
@mixin fullBg($c) {
	/* solid color image
     that fills the entire element
     and extends by 0px above an below
     and by 50vw to its sides */
	// border-image-source: conic-gradient($c 0deg, $c 0deg);
	// border-image-slice: 1 fill;
	// border-image-width: 1;
	// border-image-outset: 0 50vw;
	// border-image-repeat: stretch;
	border-image: conic-gradient($c 0 0) fill 1/0/0 50vw;
}

@mixin openBox(
	$position: top-left,
	$cut: 25%,
	$color: var(--_highlight, currentColor),
	$width: 0.5rem
) {
	@if $position == top-left {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				right
				top /
				calc(100% - #{$cut})
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				100%,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				100%;
	}

	@if $position == top-right {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				calc(100% - #{$cut})
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				100%,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				100%;
	}

	@if $position == bottom-left {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				100%
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				calc(100% - #{$cut})
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				100%,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				100%;
	}

	@if $position == bottom-right {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				100%
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				calc(100% - #{$cut})
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				100%,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				100%;
	}

	@if $position == left-top {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				100%
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				calc(100% - #{$cut}),
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				100%;
	}

	@if $position == left-bottom {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				100%
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				calc(100% - #{$cut}),
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				100%;
	}

	@if $position == right-top {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				100%
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				100%,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				bottom /
				$width
				calc(100% - #{$cut});
	}

	@if $position == right-bottom {
		background: linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				100%
				$width,
			linear-gradient(0deg, $color $width, transparent $width)
				no-repeat
				left
				bottom /
				100%
				$width,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				left
				top /
				$width
				100%,
			linear-gradient(90deg, $color $width, transparent $width)
				no-repeat
				right
				top /
				$width
				calc(100% - #{$cut});
	}
}

@mixin rawTypography($c: var(--_highlight), $t: "pl") {
	@extend %#{$t};

	> :where(:not(:first-child)) {
		margin-block-start: fluid-rem(1.5rem, 2rem);
	}

	&:empty {
		display: none;
	}

	.white-letters {
		color: $c;
	}

	.big-letters {
		@extend %bq;
	}

	h2,
	h3,
	strong,
	em,
	blockquote {
		color: $c;
	}

	em{
		font-style: normal;
	}

	// h2,
	// h3 {
	// 	color: $c;
	// }

	h2 {
		@extend %h2;
	}

	h3 {
		@extend %h3;
	}

	h4 {
		@extend %h4;
	}

	h5 {
		@extend %h5;
	}

	h6 {
		@extend %h6;
	}

	strong {
		@extend %pxl;
		font-weight: 700;
	}

	blockquote {
		p {
			@extend %bq;
		}
	}

	table {
		tr {
			td {
				@extend %pxl;
				background-color: v("_highlight", var(--white));
				padding-inline: 1.5rem;
				padding-block: 1rem;
				color: v("_foreground", var(--accent-shade));
			}

			&:nth-of-type(1) {
				td {
					background-color: v("_foreground", var(--accent-shade));
					color: v("_highlight", var(--white));
				}
			}

			strong {
				color: inherit;
				font-weight: 700;
			}
		}
	}
}
