@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

button,
a {
	&.link {
		@extend %h4;
		display: inline-flex;
		position: relative;
		align-items: center;
		gap: 0.875rem;
		transform: translateX(-1.5rem);
		transition: background-size 0.4s v("easeInOutQuad") 0.15s,
			transform 0.4s v("easeInOutQuad"), color 0.4s linear;
		background: linear-gradient(var(--black), var(--black)) no-repeat left
			bottom / 100% 0;
		isolation: isolate;
		padding: 0.9rem 1.6rem;
		line-height: 1;
		text-transform: lowercase;
		overflow-wrap: break-word;

		svg {
			transition: transform 0.3s ease-in-out;
			width: 0.75em;
			height: auto;
		}

		&:focus,
		&:hover {
			transform: translateX(0);
			background: linear-gradient(var(--black), var(--black)) no-repeat
				left bottom / 100% 100%;
			color: v("white");

			svg {
				transform: translateX(0.5rem);
			}
		}

		&:focus {
			outline: 1px solid transparent;
		}

		&[data-size="big"] {
			@extend %h2;
			text-transform: uppercase;
			white-space: nowrap;
		}

		&[data-no-transform="true"] {
			transform: translateX(0);
		}
	}
}

.service__link {
	padding-block: 0.4em;
}
