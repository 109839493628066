@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.skip-to-content {
	position: fixed;
	left: 1rem;
	z-index: 9999;
	transition: background-size 0.2s ease-in-out;
	cursor: pointer;
	background: linear-gradient(var(--black), var(--black)) no-repeat left top /
		0px 100%;
	padding: 0.5rem;
	color: v("white");

	&:focus {
		outline: 1px solid transparent;
		background-size: 100% 100%;
	}

	&:not(:focus) {
		@extend %sr-only;
	}
}
