@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.sticky-controls {
	display: grid;
	position: fixed;
	gap: 0.5rem;
	z-index: v("z-controls");
	// mix-blend-mode: multiply;
	inset: auto 0.5rem 1rem auto;
	// color: v("accent");

	// &:hover {
	// 	mix-blend-mode: normal;
	// }

	@media (width < 1400px) {
		gap: 0;
	}

	&__search,
	&__to-top {
		@include size(3.5rem);
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-size 0.4s ease;
		cursor: pointer;
		background: linear-gradient(var(--black), var(--black)) no-repeat left
			bottom / 100% 0px;
		padding: 0.5rem;
		color: v("white");

		&:focus,
		&:hover {
			background: linear-gradient(var(--black), var(--black)) no-repeat
				left bottom / 100% 100%;
			// color: v("white");
		}

		&:focus {
			outline: 1px solid transparent;
		}

		svg {
			width: 2rem;
			height: auto;
		}
	}

	&__to-top {
		@media (width >= 1400px) {
			height: auto;

			.mobile {
				display: none;
			}
		}

		@media (width < 1400px) {
			.desktop {
				display: none;
			}
		}
	}

	&__search {
		margin: 0;
		border: 0;
	}
}
