@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.search-result {
	display: grid;
	position: relative;
	align-items: start;
	gap: 1rem;
	transform: translateX(-0.5rem);
	transition: background 0.4s v(easeInOutQuad),
		transform 0.4s v(easeInOutQuad);
	margin-block-start: 0;
	background: linear-gradient(
			hsla(0deg 0% 100% / 0.4),
			hsla(0deg 0% 100% / 0.4)
		)
		no-repeat left bottom / 100% 1px;
	padding: 0.5rem;

	@media (width > 550px) {
		grid-template-columns: min-content 1fr;
	}

	&:not(:first-child) {
		margin-block-start: 1.25em;

		@media (width > 550px) {
			margin-block-start: 0;
		}
	}

	&:hover {
		transform: translateX(0%);
		background: linear-gradient(
				hsla(0deg 0% 100% / 0.4),
				hsla(0deg 0% 100% / 0.4)
			)
			no-repeat left bottom / 100% 100%;

		.search-result__link {
			--_highlight: var(--black);
		}
	}

	&__type {
		@extend %h6;
		width: 8rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__link {
		@extend %h5;
		transition: color 0.4s ease-in-out;
		color: v(_highlight);

		&::before {
			position: absolute;
			inset: 0;
			content: "";
		}
	}
}
