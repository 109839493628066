html {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	-webkit-text-size-adjust: auto;
	-ms-text-size-adjust: auto;
	-webkit-font-smoothing: antialiased;
	text-size-adjust: auto;
	font-feature-settings: "kern" 1;
	font-kerning: normal;
	text-rendering: optimizeLegibility; //optimizeSpeed;
}

/* Change Autocomplete styles in Chrome*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

/*p,
h1, h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
	// hyphens: auto;
}*/

a {
	word-wrap: break-word;
	color: inherit;
	overflow-wrap: break-word;
}
