@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.why-us {
	padding: fluid-rem(2rem, 4rem);
	color: v("_text", inherit);

	&__title {
		@extend %h6;
		margin-block-end: 2em;
	}

	&__content {
		@extend %pl;

		ul {
			display: grid;
			grid-template-columns: repeat(
				auto-fit,
				minmax(#{fluid-rem(12rem, 18rem)}, 1fr)
			);
			gap: 2.25rem;
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				display: flex;
				align-items: baseline;
				gap: 1rem;

				&::before {
					@include size(0.625rem);
					flex-grow: 0;
					flex-shrink: 0;
					background-color: v("_highlight");
					content: "";
				}
			}
		}
	}
}
