@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.main {
	> :first-child {
		margin-block-start: fluid-rem(4rem, 8rem);
	}

	// Basic page like Privacy Policy
	.page {
		@extend %container;

		&__title {
			@extend %h1;
			margin-block: fluid-rem(3rem, 6rem);
			color: var(--white);
			text-align: center;
			overflow-wrap: break-word;
		}

		&__content {
			@include rawTypography($c: var(--black), $t: "pxl");

			a {
				color: var(--white);
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}

			h2 {
				@extend %h3;
				text-transform: initial;
			}
			h3 {
				@extend %h4;
				text-transform: initial;
			}
			h4,
			h5 {
				@extend %h5;
				text-transform: initial;
			}
			h5 {
				@extend %h5;
				text-transform: initial;
			}
		}
	}
}

.section {
	padding-block: fluid-rem(4rem, 8rem);

	&__blockquote,
	&__title,
	&__cta {
		color: v("_highlight", var(--white));
	}

	// img,
	// picture {
	// 	&.section__graphic,
	// 	&.section__image {
	// 		aspect-ratio: v("ratio");
	// 		width: min(var(--max-width), 100%);

	// 		@media (width < 768px) {
	// 			// width: min(calc(var(--max-width) / 2), 100%);
	// 			width: min(var(--max-width), 70%);
	// 		}
	// 	}
	// }

	&__graphic,
	&__image {
		aspect-ratio: v("ratio");
		width: min(var(--max-width), 100%);

		@media (width < 768px) {
			width: min(var(--max-width), 70%);
		}
	}

	&__title {
		@extend %h2;
	}

	&__raw {
		@include rawTypography();
	}

	&__blockquote {
		@extend %bq;
	}

	&__subtitle {
		@extend %h5;
	}
}

.white-letters,
.big-letters {
	color: var(--_highlight, white);
}

.big-letters {
	@extend %bq;
}

.loading {
	background: url("../../images/loader.gif") no-repeat center;
	height: 9px;
}
