@use "sass:color" as c;
@use "../functions" as *;
@use "../mixins" as *;
@use "../helpers" as *;
@use "../typographic-elements" as *;

.get-in-touch {
	position: fixed;
	z-index: v("z-controls");
	inset: auto auto 5.75rem 0.5rem;

	&-link {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-size 0.4s ease;
		cursor: pointer;
		background: linear-gradient(var(--black), var(--black)) no-repeat left
			bottom / 100% 0px;
		padding: 0.5rem;
		width: 3.5rem;
		height: auto;
		color: v("white");

		@at-root .page-get-in-touch & {
			display: none;
		}

		@media (width < 1200px) {
			display: none;
		}

		&:focus,
		&:hover {
			background: linear-gradient(var(--black), var(--black)) no-repeat
				left bottom / 100% 100%;
			// color: v("white");
		}

		&:focus {
			outline: 1px solid transparent;
		}

		&__icon {
			width: 2.5rem;
			height: auto;
		}
	}
}
